import { BigintIsh } from '@uniswap/sdk-core'
import axios from 'axios'

const cache: { [key: string]: string } = {}

const DEFAULT_DENOMINATOR = 100000000

export const loadTokenPrices = async () => {
  const url = 'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin,planq&vs_currencies=usd'
  const { data } = await axios.get(url)

  cache['WBNB'] = data['binancecoin'].usd
  cache['PLQ'] = data['planq'].usd
}

export const getTokenPrice = (symbol: string | undefined): BigintIsh => {
  if (Object.keys(cache).length === 0) {
    cache['WBNB'] = '250'
    cache['PLQ'] = '0.025'
  }
  return symbol ? Math.floor(parseFloat(cache[symbol]) * DEFAULT_DENOMINATOR) : '0'
}
