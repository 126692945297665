// import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.PLANQ_TESTNET]: '0xBdf1b3fA319ea090176a8510B88DE39e11E97852',
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x925E20BD2d35fB8E04C5fF361Bb97C8bD9DEdccA',
  [SupportedChainId.PLANQ_TESTNET]: '0x1DAf9623ABCE2a6A64755f6D7016DfC0C00A2F31',
}

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x67b674951E611c71a9a2B153b3f1d28fe0b66717',
  [SupportedChainId.PLANQ_TESTNET]: '0x7992E85e218e933d4b99fa6470c2e324AFFC0837',
}

export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x81757C021f411aa934e798E7B25D4f1ebE55723e',
  [SupportedChainId.PLANQ_TESTNET]: '0x1160e630600162424bF31Cd6ba1503019E4b673E',
}

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x0000000000000000000000000000000000000000',
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x0000000000000000000000000000000000000000',
}

export const QUOTER_ADDRESSES: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x2D350c0616CC057A4514470CD7E52cFB13AbBFf5',
  [SupportedChainId.PLANQ_TESTNET]: '0x43D4Fb8F05B1C4eC4f983309A7a3e7ed39720edd',
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x703d84d53af63530506F893e221594E35Dca2Fa1',
  [SupportedChainId.PLANQ_TESTNET]: '0x5Ac64E88310B4A076e559eee9AaFD1f326F1f36D',
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.PLANQ_TESTNET]: '0x0000000000000000000000000000000000000000',
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  [SupportedChainId.PLANQ_MAINNET]: '0x3D6ADe2D74D3A57b141D86FABBfA9aBbbb39E46C',
  [SupportedChainId.PLANQ_TESTNET]: '0xdCAC02143e1c9b9C88CfC5283A5825e0f71FAb5F',
}
