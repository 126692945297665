import { USDC_MAINNET, USDT_MAINNET, WBNB_MAINNET, WNATIVE_MAINNET } from './tokens'

export const tokenLogos = {
  planq_mainnet: {
    [WNATIVE_MAINNET.address]:
      'https://github.com/planq-network/brand-assets/blob/main/Planq%20Logo/logo%20twitter2.png',
    [USDC_MAINNET.address]:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
    [USDT_MAINNET.address]:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
    [WBNB_MAINNET.address]:
      'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x418D75f65a02b3D53B2418FB8E1fe493759c7605/logo.png',
  },
}
